import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input()
  public type: string = 'btn-primary';

  @Input()
  public label: string = '';

  @Input()
  public disabled: boolean | null = false;

  @Input()
  public full: boolean = false;

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();
}
